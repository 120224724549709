<template>
  <div :class="iconClass" :style="{ width: size + 'px', height: size + 'px', marginRight: '0.3rem' }">
    <slot>
      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
        <path fill-rule="evenodd" clip-rule="evenodd"
          d="M8.48473 1.83332C8.48473 1.37309 8.85782 1 9.31805 1H10.9847C11.4449 1 11.818 1.37309 11.818 1.83332V2.62119L11.8179 2.63662H13.4843C13.9445 2.63662 14.3176 3.00971 14.3176 3.46994V4.25781C14.3176 4.71804 13.9445 5.09113 13.4843 5.09113H6.8177C6.35747 5.09113 5.98438 4.71804 5.98438 4.25781V3.46994C5.98438 3.00971 6.35747 2.63662 6.8177 2.63662H8.48487L8.48473 2.62119V1.83332ZM4.31801 2.63664H5.15186V5.07574C5.15186 5.53597 5.52494 5.90906 5.98517 5.90906H14.3184C14.7786 5.90906 15.1517 5.53597 15.1517 5.07574V2.63664H15.9845C16.9049 2.63664 17.6511 3.38282 17.6511 4.30328V17.3334C17.6511 18.2538 16.9049 19 15.9845 19H4.31801C3.39755 19 2.65137 18.2538 2.65137 17.3334V4.30328C2.65137 3.38282 3.39755 2.63664 4.31801 2.63664ZM5.98518 13.2725C5.52495 13.2725 5.15186 13.6456 5.15186 14.1059V15.7119C5.15186 16.1721 5.52495 16.5452 5.98517 16.5452H6.8185C7.27873 16.5452 7.65182 16.1721 7.65182 15.7119V14.1059C7.65182 13.6456 7.27873 13.2725 6.8185 13.2725H5.98518ZM8.48438 14.5076C8.48438 14.2775 8.67092 14.091 8.90103 14.091H14.7343C14.9644 14.091 15.1509 14.2775 15.1509 14.5076V15.3106C15.1509 15.5408 14.9644 15.7273 14.7343 15.7273H8.90103C8.67092 15.7273 8.48438 15.5408 8.48438 15.3106V14.5076ZM5.98518 8.36351C5.52495 8.36351 5.15186 8.7366 5.15186 9.19683V10.8029C5.15186 11.2631 5.52495 11.6362 5.98517 11.6362H6.8185C7.27873 11.6362 7.65182 11.2631 7.65182 10.8029V9.19683C7.65182 8.7366 7.27873 8.36351 6.8185 8.36351H5.98518ZM8.48438 9.59841C8.48438 9.36829 8.67092 9.18175 8.90103 9.18175H14.7343C14.9644 9.18175 15.1509 9.36829 15.1509 9.59841V10.4014C15.1509 10.6315 14.9644 10.8181 14.7343 10.8181H8.90103C8.67092 10.8181 8.48438 10.6315 8.48438 10.4014V9.59841Z"
          fill="currentColor" />
      </svg>
    </slot>
  </div>
</template>

<script>
export default {
  name: 'IconInventory',
  props: {
    iconClass: {
      type: String,
      default: '',
    },

    size: {
      type: [Number, String],
      default: 25,
    },
  },
};
</script>

<style scoped>
/* You can add any additional styles here */
</style>

// src/directives/permission.js

import { MODULE_NAME, PERMISSION } from '@/constants/permission.constant';

export default {
  inserted(el, binding, vnode) {
    const userPermissions = vnode.context.$store.getters['getUserPermission'];
    const requiredPermission = binding.value;
    const hasAction = _.some(
      userPermissions,
      (item) => _.includes(item.action, requiredPermission)
      // _.includes(MODULE_NAME, requiredPermission)
    );
    const hasModuleName = userPermissions.find((m) => m.moduleName == requiredPermission);
    if (hasAction || hasModuleName || requiredPermission == MODULE_NAME.CONFIGURATION || requiredPermission == PERMISSION.DEFAULT) {
      el.style.display = ''; // Hide the element
    } else {
      el.style.display = 'none'; // Show the element
    }
  },

  update(el, binding, vnode) {
    const userPermissions = vnode.context.$store.getters['getUserPermission'];

    const requiredPermission = binding.value;
    const hasAction = _.some(
      userPermissions,
      (item) => _.includes(item.action, requiredPermission)
      // _.includes(MODULE_NAME, requiredPermission)
    );
    const hasModuleName = userPermissions.find((m) => m.moduleName == requiredPermission);

    if (hasAction || hasModuleName || requiredPermission == MODULE_NAME.CONFIGURATION || requiredPermission == PERMISSION.DEFAULT) {
      el.style.display = ''; // Hide the element
    } else {
      el.style.display = 'none'; // Show the element
    }
  },

  bind(el, binding, vnode) {
    const checkPermission = () => {
      const userPermissions = vnode.context.$store.getters['getUserPermission'];
      const requiredPermission = binding.value;
      const hasAction = _.some(
        userPermissions,
        (item) => _.includes(item.action, requiredPermission)
      );
      const hasModuleName = userPermissions.find((m) => m.moduleName == requiredPermission);

      if (hasAction || hasModuleName || requiredPermission == MODULE_NAME.CONFIGURATION || requiredPermission == PERMISSION.DEFAULT) {
        el.style.display = ''; // Hide the element
      } else {
        el.style.display = 'none'; // Show the element
      }
    };

    checkPermission();

    // Watch for changes in user permissions
    vnode.context.$store.watch(
      (state) => state.user.permission,
      () => {
        checkPermission();
      }
    );
  },
};

export function formatCurrency(value, locale = 'en-US', currency = 'USD') {
  if (typeof value !== 'number') {
    console.warn('Value passed to formatCurrency is not a number.');
    return value;
  }
  return new Intl.NumberFormat(locale, {
    style: 'currency',
    currency,
  }).format(value);
}

export function formatCurrencyDash(value, locale = 'en-US', currency = 'USD') {
  if (value === null || value === undefined || value === 0) {
    return '--';
  }

  if (typeof value !== 'number') {
    console.warn('Value passed to formatCurrencyAndDash is not a number.');
    return value;
  }
  try {
    return new Intl.NumberFormat(locale, {
      style: 'currency',
      currency,
    }).format(value);
  } catch (error) {
    console.error(`Invalid locale or currency: ${locale}, ${currency}`, error);
    return '--';
  }
}

import { PERMISSION } from '@/constants/permission.constant';
export const inventoriesRoute = [
  {
    path: 'inventory/category',
    name: 'inventory-catory',
    component: () => import('@/views/Inventories/category/index.vue'),
    meta: { requiresAuth: true, permissions: [PERMISSION.DASHBOARD_READ] },
  },
  {
    path: 'inventory/category/new',
    name: 'inventory-catory-new',
    component: () => import('@/views/Inventories/category/CreateCategory.vue'),
    meta: { requiresAuth: true, permissions: [PERMISSION.DASHBOARD_READ] },
  },
  {
    path: 'inventory/category/edit/:id',
    name: 'inventory-catory-edit',
    component: () => import('@/views/Inventories/category/EditCategory.vue'),
    meta: { requiresAuth: true, permissions: [PERMISSION.DASHBOARD_READ] },
  },
  {
    path: 'inventory/storage/new',
    name: 'inventory-storage-new',
    component: () => import('@/views/Inventories/storage/CreateStorage.vue'),
    meta: { requiresAuth: true, permissions: [PERMISSION.DASHBOARD_READ] },
  },

  {
    path: 'inventory/storage',
    name: 'inventory-storage',
    component: () => import('@/views/Inventories/storage/index.vue'),
    meta: { requiresAuth: true, permissions: [PERMISSION.DASHBOARD_READ] },
  },
  {
    path: 'inventory/storage/edit/:id',
    name: 'inventory-storage-edit',
    component: () => import('@/views/Inventories/storage/EditStorage.vue'),
    meta: { requiresAuth: true, permissions: [PERMISSION.DASHBOARD_READ] },
  },

  {
    path: 'inventory/parts',
    name: 'inventory-part',
    component: () => import('@/views/Inventories/parts/index.vue'),
    meta: { requiresAuth: true, permissions: [PERMISSION.DASHBOARD_READ] },
    children: [
      // part routes
      {
        path: '/',
        name: 'inventory-parts',
        component: () => import('@/views/Inventories/parts/part/index.vue'),
        meta: { requiresAuth: true, permissions: [PERMISSION.DASHBOARD_READ] },
      },

      {
        path: 'part-new',
        name: 'inventory-parts-new',
        component: () => import('@/views/Inventories/parts/part/CreatePart.vue'),
        meta: { requiresAuth: true, permissions: [PERMISSION.DASHBOARD_READ] },
      },

      {
        path: 'part-edit/:id',
        name: 'inventory-parts-edit',
        component: () => import('@/views/Inventories/parts/part/EditPart.vue'),
        meta: { requiresAuth: true, permissions: [PERMISSION.DASHBOARD_READ] },
      },

      // part issue routes
      {
        path: 'part-issue',
        name: 'inventory-issue',
        component: () => import('@/views/Inventories/parts/parts-issue/index.vue'),
        meta: { requiresAuth: true, permissions: [PERMISSION.DASHBOARD_READ] },
      },
      {
        path: 'part-issue-new',
        name: 'inventory-issue-new',
        component: () => import('@/views/Inventories/parts/parts-issue/CreatePartIssue.vue'),
        meta: { requiresAuth: true, permissions: [PERMISSION.DASHBOARD_READ] },
      },
      {
        path: 'part-issue/:id',
        name: 'inventory-issue-edit',
        component: () => import('@/views/Inventories/parts/parts-issue/ViewPartIssue.vue'),
        meta: { requiresAuth: true, permissions: [PERMISSION.DASHBOARD_READ] },
      },
      {
        path: 'part-return',
        name: 'inventory-return',
        component: () => import('@/views/Inventories/parts/parts-return/index.vue'),
        meta: { requiresAuth: true, permissions: [PERMISSION.DASHBOARD_READ] },
      },

      {
        path: 'part-return-edit/:id',
        name: 'inventory-return-edit',
        component: () => import('@/views/Inventories/parts/parts-return/EditPartReturn.vue'),
        meta: { requiresAuth: true, permissions: [PERMISSION.DASHBOARD_READ] },
      },

      {
        path: 'part-return-new',
        name: 'inventory-return-new',
        component: () => import('@/views/Inventories/parts/parts-return/CreatePartReturn.vue'),
        meta: { requiresAuth: true, permissions: [PERMISSION.DASHBOARD_READ] },
      },
    ],
  },
];

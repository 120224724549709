import { DATE_FORAT } from '@/constants/ConstantType';
import moment from 'moment';

Array.prototype.toDropdownlist = function () {
  return this.map((m) => ItemSelected({ text: m.name, value: m.id }));
};

Array.prototype.toSelectedByValue = function ({ value, defaultValue }) {
  const itemsSelected = this.find((m) => m.value == value);
  if (!itemsSelected) {
    if (defaultValue) return this.find((m) => m.value == defaultValue);
    else {
      return {};
    }
  } else {
    return itemsSelected;
  }
};
// Extend the Array prototype with a custom sortBy method
Array.prototype.sortBy = function (property, order = 'asc') {
  return this.sort((a, b) => {
    // Handle nested properties (e.g., 'person.age')
    const valueA = property.split('.').reduce((obj, key) => obj[key], a);
    const valueB = property.split('.').reduce((obj, key) => obj[key], b);

    // Compare values
    if (valueA < valueB) {
      return order === 'asc' ? -1 : 1;
    }
    if (valueA > valueB) {
      return order === 'asc' ? 1 : -1;
    }
    return 0; // Values are equal
  });
};

String.prototype.mileageStringFormat = function () {
  const v = this || '';
  const [mileageNumber = 0, unit = 'Km'] = (v || '')?.split(':');
  return mileageNumber ? mileageNumber + ' ' + unit : '';
};

String.prototype.mileageObjectFormat = function () {
  const v = this || '';
  const [mileageNumber = 0, unit = 'Km'] = (v || '')?.split(':');
  return { unit, value: mileageNumber };
};

String.prototype.defaultValueTable = function (defaultValue) {
  return this || defaultValue;
};

String.prototype.toTitleCase = function () {
  const spacedStr = this.replace(/([a-z])([A-Z])/g, '$1 $2');

  // Convert snake_case to space-separated words
  const spacedStrWithUnderscore = spacedStr.replace(/_/g, ' ');

  // Convert to Title Case
  return spacedStrWithUnderscore.replace(/\w\S*/g, (txt) => {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
};

export function ItemSelected({ text = null, value = null }) {
  const selectItem = { text: text, value: value };
  if (value) return selectItem;
  else {
    return null;
  }
}

export function isObjectNotEmpty(obj) {
  // the properties of the object
  let isContainTrue = [];
  for (let key in obj) {
    const value = obj[key];

    // If the property is an array, check if it's not empty
    if (value) {
      if (Array.isArray(value) && value) {
        if (value.length) {
          isContainTrue.push(true);
        }
      } else if (typeof value == 'object' && value) {
        if (Object.keys(value)?.length) isContainTrue.push(true);
      } else {
        isContainTrue.push(true);
      }
    }
  }

  const isTrue = isContainTrue?.find((v) => v);
  return isTrue;
}

export function dateFormatLocal(date, format = 'YYY/MM/DD HH:mm:ss [GMT]') {
  const dateForm = moment(date, format);
  const startDate = dateForm.format(DATE_FORAT.LOCAL_DATE); // January 1st, 00:00

  return startDate;
}

export function getStartAndEndOfYear(year) {
  const format = 'ddd MMM DD YYYY HH:mm:ss [GMT]ZZ (zzzz)';
  const dateNow = new Date();
  const dateYear = moment(dateNow, format).year(year);
  const startDate = dateYear.startOf('year').format(DATE_FORAT.LOCAL_DATE); // January 1st, 00:00
  const endDate = dateYear.endOf('year').format(DATE_FORAT.LOCAL_DATE); // December 31st, 23:59

  return {
    startDate,
    endDate,
  };
}

function getNestedValue(obj, path, delimiter = '/') {
  const keys = path.split(delimiter);

  return keys.reduce((acc, key) => {
    return acc ? acc[key] : undefined;
  }, obj);
}

function setNestedValue(obj, path, value, delimiter = '/') {
  const keys = path.split(delimiter);

  keys.reduce((acc, key, index) => {
    // If this is the last key, set the value
    if (index === keys.length - 1) {
      acc[key] = value;
    } else {
      // Otherwise, keep iterating down the object, creating new objects if necessary
      if (!acc[key]) {
        acc[key] = {}; // create empty object if it doesn't exist
      }
    }
    return acc[key]; // move down the object
  }, obj);
}

export const CONST_MILLEAGE = {
  MILEAGE: 'MILEAGE',
  KM: 'Km',
  MILE: 'Mile',
};

export const TYPE_CONTROL = {
  SINGLE_SELECT: 'single-select',
  MULTI_SELECT: 'multi-select',
  SINGLE_SELECT_ASYNC: 'single-select-async',
  SINGLE_SELECT_LOOKUP_ASYNC: 'single-select-lookup-async',
  MULTI_SELECT_ASYNC: 'multi-select-async',
  INPUT_TEXT: 'text',
  INPUT_NUMBER: 'number',
  INPUT_DATE: 'datetime',
};

import { fetchData } from './data-api.service'; // Adjust the import path

const state = {
  options: {},
  isLoading: false,
};

const mutations = {
  SET_OPTIONS(state, { endpoint, options }) {
    state.options = {
      ...state.options,
      [endpoint]: options,
    };
  },
  SET_LOADING(state, isLoading) {
    state.isLoading = isLoading;
  },
};

const actions = {
  async fetchOptions({ commit }, { endpoint, query }) {
    commit('SET_LOADING', true);
    try {
      const response = await fetchData(endpoint, { query });

      commit('SET_OPTIONS', {
        endpoint,
        options: response.content?.map((d) => ({ ...d, url: endpoint })),
      }); // Adjust based on your API response structure
    } catch (error) {
      console.error('Error fetching options:', error);
    } finally {
      commit('SET_LOADING', false);
    }
  },
};

const getters = {
  options: (state) => (endpoint) => state.options[endpoint] || [],
  isLoading: (state) => state.isLoading,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};

<template>
  <div class="text-input" :class="{ 'requied-custome label-required': requred }">
    <b-form-group :label="''" :label-for="id" :invalid-feedback="invalidFeedback" :valid-feedback="validFeedback"
      class="text-left" :state="computedState" :description="description">
      <b-form-input :id="id" v-model="formattedValue" :state="computedState" :placeholder="placeholder"
        :type="inputType" @blur="onBlur" @input="onInput" @keypress="onKeyPress" :disabled="disabled" />
    </b-form-group>

  </div>
</template>

<script>
export default {
  name: 'BaseTextInput',
  props: {
    disabled: { type: Boolean, default: false },
    requred: { type: Boolean, default: false },
    value: {
      type: [String, Number],
      default: '',
    },
    label: {
      type: String,
      default: 'Input',
    },
    id: {
      type: String,
      default: null,
    },
    placeholder: {
      type: String,
      default: 'Enter text',
    },
    inputType: {
      type: String,
      default: 'text',
    },
    description: {
      type: String,
      default: '',
    },
    invalidFeedback: {
      type: String,
      default: 'This field is required.',
    },
    validFeedback: {
      type: String,
      default: '',
    },
    state: {
      type: Boolean,
      default: null,
    },
  },
  computed: {
    computedState() {
      return this.state === null ? null : this.state;
    },

    formattedValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
  methods: {
    onBlur() {
      this.$emit('blur');
    },
    onInput(event) {
      // Ensure `event` is defined and `event.target` exists
      if (event && event.target) {
        this.$emit('input', event.target.value);
      }
    },
    onKeyPress(event) {
      this.$emit('keypress', event);
      // Optionally, restrict input here
    },
  },
};
</script>

<style scoped>
.required legend {
  position: relative;
}

.required legend::after {
  content: '*';
  position: absolute;
  left: 0;
  top: 0;
}

.text-input {
  /* Add your custom styles here */
}
</style>

<template>
    <div style="position: relative;">
        <b-form-input @input="$emit('input', $event)" :id="inputId" :name="inputName" style="
        opacity: 0; 
        width: 0; 
        height: 0;
        position: absolute;
        z-index: -1;
         " :state="validateState" :value="localValue" :class="customClass"
            :aria-describedby="ariaDescribedby"></b-form-input>

        <multi-select :disabled="disabled" v-model="selectedItem" @select="onSelect" track-by="value"
            :allow-empty="allowEmpty" :deselect-label="deselectLabel" :options="options" :searchable="searchable"
            :custom-label="customLabel" :close-on-select="closeOnSelect" :placeholder="placeholder"></multi-select>

        <b-form-invalid-feedback :id="feedbackId" v-if="!validateState">
            {{ invalidFeedback }}
        </b-form-invalid-feedback>
    </div>
</template>

<script>
import { v4 as uuidv4 } from 'uuid';
export default {
    name: 'AppSelectForm',
    props: {
        disabled: {
            type: Boolean,
            default: false,
        },
        label: {
            type: String,
            required: false,
            default: '',
        },
        ariaDescribedby: {
            type: String,
            default: '',
        },
        inputId: {
            type: String,
            required: false,
            default: () => uuidv4(),

        },
        inputName: {
            type: String,
            required: false,
        },
        customClass: {
            type: String,
            default: '',
        },
        invalidFeedback: {
            type: String,
            default: '',
        },
        feedbackId: {
            type: String,
            default: '',
        },
        validateState: {
            type: Boolean,
            default: null,
        },
        inputModel: {
            type: [String, Number],
            default: '',
        },
        value: {
            type: [Number, String, Object],
            default: () => null,
        },
        selectModel: {
            type: [Array, Object, String, Number],
            default: () => [],
        },
        isDisabled: {
            type: Boolean,
            default: false,
        },
        trackBy: {
            type: String,
            default: 'value',
        },
        allowEmpty: {
            type: Boolean,
            default: true,
        },
        deselectLabel: {
            type: String,
            default: 'Select Existed',
        },
        options: {
            type: Array,
            // required: true,
            default: () => [],
        },
        searchable: {
            type: Boolean,
            default: true,
        },
        customLabel: {
            type: Function,
            default: null,
        },
        closeOnSelect: {
            type: Boolean,
            default: true,
        },
        placeholder: {
            type: String,
            default: 'Select an option..',
        },
    },
    watch: {
        inputModel(value) {
            this.inputModelLocal = value;
        },
        selectModel(value) {
            this.selectModelLocal = value;
        }
    },
    computed: {
        selectedItem: {
            get() {
                return this.options?.find(option => option.value == this.value) || null;
            },
            set(newValue) {
                this.$emit('input', newValue.value);
            }
        }
        // Add any computed properties here
    },
    data() {
        return {
            // Add any data here
            inputModelLocal: null,
            selectModelLocal: {},
            localValue: null
        };
    },
    methods: {
        onSelect(selectedOption) {
            // selectedOption.value
            this.localValue = selectedOption.value;
            this.$emit('update', selectedOption.value);
            this.$emit('input', selectedOption.value);
            this.$emit('update:inputModel', selectedOption.value);
            this.$emit('update:selectModel', selectedOption);
            this.$emit('select', selectedOption);
        },
    },
    mounted() {
        this.selectModelLocal = this.selectModel;
        this.inputModelLocal = this.value;


    }
};
</script>

<style scoped>
/* Add any custom styles here */
</style>
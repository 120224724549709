import { CredentialService } from '@/services/CredentialService';
import axios from 'axios';
axios.defaults.baseURL = process.env.VUE_APP_DEV_URL;

const api = axios.create({
  headers: {
    'Content-Type': 'application/json',
  },
});

api.interceptors.request.use(
  (config) => {
    const credential = CredentialService.getCredential();
    const authToken = credential.accessToken;
    if (authToken) {
      config.headers.Authorization = `Bearer ${authToken}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error.message);
  }
);

api.interceptors.response.use(
  (response) => response,
  (error) => {
    return Promise.reject(error);
  }
);

export default api;

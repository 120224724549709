import api from '@/services/AxiosConfig';
import { ConfigurationService } from '@/services/ConfigurationSetup';
import { MPaginitions } from '@/share/helper.ulity';
import moment from 'moment';

export class ModelLeads {
  static get dropdown() {
    const configuration = new ConfigurationService();
    return configuration.findAllDropdownLocal() || {};
  }

  constructor(data) {
    this.id = data?.id;
    this.createdBy = data?.createdBy || 'You';
    this.sourceId = data?.sourceId;
    this.customerName = data?.customerName;
    this.phoneNumber = data?.phoneNumber;
    this.provinceId = data?.provinceId;
    this.provinceNameEn=data.provinceNameEn;
    this.plateNumber = data?.plateNumber;
    this.brandId = data?.brandId;
    this.jobCardId=data.jobCardId;
    this.modelId = data?.modelId;
    this.year = data?.year;
    this.colorId = data?.colorId;
    this.serviceTypeIds = data?.serviceTypeIds;
    this.serviceAdvisorIds = data?.serviceAdvisorIds;
    this.leadsComplains = data?.leadsComplains;
    this.remark = data?.remark;
    this.statusCode = data?.statusCode;
    this.isActive = data?.isActive;
    this.active = data.active;
    this.createdAt = data.createdAt;
    this.cityId = data.provinceId;
    this.source = data?.source;
    this.followUpDate = data.followUpDate;
    this.bookedDate = data.bookedDate;
    this.bookedDateLocal = data.bookedDate ? moment(data.bookedDate).format('DD/MM/YY -hh:mm A') : '';
    this.followUpDateLocal = data.followUpDate ? moment(data.followUpDate).format('DD/MM/YY -hh:mm A') : '';
    this.createdAtLocal = moment(data.createdAt).format('DD/MM/YY');
    this.serviceTypes = data.serviceTypes?.length
      ? ModelLeads.dropdown.SERVICE_TYPE?.filter((dt) => data?.serviceTypes?.find((a) => a.id == dt.id)).map(({ code, name: text, id: value }) => ({ code, value, text }))
      : [];

    this.brand = {};
    this.source = {};
    this.model = {};
    this.color = {};
    this.city = {};
    this.serviceType = [];
    this.serviceAdvisors = [];

    this.serviceType = data?.serviceTypes;
    this.serviceAdvisors = data?.serviceAdvisors;

    const _color = ModelLeads.dropdown?.COLOR?.find((br) => br?.id == data?.colorId);
    this.color = _color;

    const _city = ModelLeads.dropdown?.CITY?.find((c) => c?.id == data?.provinceId);

    this.city = _city;

    const vbrand = ModelLeads.dropdown?.BRAND?.find((br) => br?.id == data?.brandId);
    this.brand = vbrand;

    const vource = ModelLeads.dropdown?.SOURCE?.find((src) => src?.id == data?.sourceId);
    this.source = vource;

    const vmodel = ModelLeads.dropdown?.MODEL?.find((mod) => mod?.id == data?.modelId);
  
    this.model = vmodel;
  }

  formModel() {}
  // You can add methods to this class if needed
  activate() {
    this.isActive = true;
  }

  deactivate() {
    this.isActive = false;
  }
}
const _URL_API = 'leads';
const _DROPDOWNLIST = 'dropdown/get-leads-options';
const REMARK = 'remark';
export class LeadsService {
  static save(body) {
    const parmsBody = { ...body };
    return api
      .post(`${_URL_API}`, parmsBody)
      .then((res) => ({ success: res.data }))
      .catch((er) => {
        console.log(er);
        return { error: er };
      });
  }

  static update({ id, body }) {
    const parmsBody = body;
    return api
      .put(`${_URL_API}/${id}`, parmsBody)
      .then((res) => ({ success: res?.data }))
      .catch((er) => ({ error:er }));
  }

  static getListLeads({ params }) {
    const findLeads = api
      .get(_URL_API, { params })
      .then((res) => ({
        success: new MPaginitions({
          ...res.data,
          pageNo: res.data.pageNo + 1,
          content: res.data.content.map((m) => ({ ...new ModelLeads(m) })),
        }),
      }))
      .catch((er) => ({ error: er }));
    return findLeads;
  }

  static getDropdownLeads() {
    const findAllDropdown = api
      .get(_DROPDOWNLIST)
      .then((d) => ({ success: d.data }))
      .catch((er) => ({ error: er }));
    return findAllDropdown;
  }
  

 static async downloadExcel({  params,columnsQuery }) {
    try {
        const response = await api.get(`${_URL_API}/excel/download?columns=${columnsQuery}`, {
          params, 
          responseType: 'blob', // Important: Set the response type to 'blob' to handle binary data
        });
        const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

        // Create a link element for download
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = 'Leads_'+moment().format("DD_MM_YY")+'.xlsx'; // The name of the downloaded file
        link.click();

        // Clean up the URL object
        window.URL.revokeObjectURL(link.href);

        console.log('Excel file downloaded successfully!');
    } catch (error) {
        console.error('Error downloading the Excel file:', error);
    }
}

  static getLeadsById({ id }) {
    return api
      .get(`${_URL_API}/${id}`)
      .then((res) => ({ success: new ModelLeads(res.data) }))
      .catch((er) => {
        console.log(er);
        return { error: er };
      });
  }


  static async getRemark(leadsId) {
    return api
      .get(`${_URL_API}/${REMARK}/${leadsId}`)
      .then((d) => ({ success: d.data }))
      .catch((er) => ({ error: er }));
  }
}

export function MappFormData({ data, citiesOption, STATUS_LIST }) {
  
  const { brand, source, serviceTypes, serviceType, serviceAdvisors, followUpDate, bookedDate, model, color, statusCode, ...propsData } = {
    ...new ModelLeads({...data}),
  };

  const cities = citiesOption.find((m) => m.value == data.cityId);
  const findServiceType = serviceType &&  Array.isArray(serviceType)? serviceType.find((m) => m): serviceType;
 
  const findServiceAdvisor = (serviceAdvisors &&  Array.isArray(serviceAdvisors) ? serviceAdvisors?.find((m) => m) : serviceAdvisors);

  
  const formModel = {
      ...(propsData || {}),
      brand: brand?.id ? { text: brand?.name, value: brand?.id } : null,
      status: STATUS_LIST?.find((m) => m?.value == statusCode),
      source: source?.id ? { text: source?.name, value: source?.id } : null,
      model: model?.id ? { text: model?.name, value: model?.id } : null,
      city: cities?.value ? cities : null,
      createdBy: data?.createdBy || 'You',
      color: color?.id ? { text: color?.name, value: color?.id } : null,
      serviceAdvisor : findServiceAdvisor?{value:findServiceAdvisor?.id,text:findServiceAdvisor?.fullName}:null,
      validServiceTypeId : findServiceType?.value,
      serviceType : findServiceType,
      followUpDate : (followUpDate ? moment(followUpDate).utc() : moment()).local().format('YYYY-MM-DD HH:mm'),
      $vServiceAdvisorId :findServiceAdvisor,
      bookedDate : (bookedDate ? moment(bookedDate).utc() : moment()).local().format('YYYY-MM-DD HH:mm'),
      
    };
  
  return formModel;
}

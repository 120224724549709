import { render, staticRenderFns } from "./IconInventory.vue?vue&type=template&id=b6628b6e&scoped=true"
import script from "./IconInventory.vue?vue&type=script&lang=js"
export * from "./IconInventory.vue?vue&type=script&lang=js"
import style0 from "./IconInventory.vue?vue&type=style&index=0&id=b6628b6e&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b6628b6e",
  null
  
)

export default component.exports
// import { fetchData } from './data-api.service';

import { fetchData } from './data-api.service';

const stateInit = () => ({
  items: [],
  loading: false,
  error: null,
  currentPage: 0,
  totalItems: 0,
  pageSize: 25,
  pageNo: 0,
  filters: {},
  endpoint: '',
});

let state = stateInit();

const getters = {
  allItems: (state) => state.items,
  isLoading: (state) => state.loading,
  fetchError: (state) => state.error,
  currentPage: (state) => state.currentPage,
  totalItems: (state) => state.totalItems,
  pageSize: (state) => state.pageSize,
  filters: (state) => state.filters,
};

const actions = {
  resetState({ commit }, { endpoint }) {
    commit('resetState', stateInit());
  },

  async getItems({ commit, state }, { filters = {} }) {
    commit('setLoading', true);
    try {
      const data = await fetchData(state.endpoint, { ...filters, size: state?.pageSize || 25, page: state?.currentPage || 0 });
      const { content: items = [], totalElements: totalItems = 0, size = 25, page = 0 } = data;
      commit('setItems', items);
      commit('setTotalItems', totalItems);
      commit('setPageSize', size);
      commit('setCurrentPage', page);
    } catch (error) {
      commit('setError', error);
    } finally {
      commit('setLoading', false);
    }
  },

  setFilters({ commit, dispatch, state }, { filters }) {
    dispatch('getItems', { filters });
  },

  reloadTable({ commit, dispatch, state }) {
    dispatch('getItems', { filters: state.filters });
  },
};

const mutations = {
  setItems: (state, items) => (state.items = items),
  setLoading: (state, loading) => (state.loading = loading),
  setError: (state, error) => (state.error = error),
  setCurrentPage: (state, page) => (state.currentPage = page),
  setPageNo: (state, page) => (state.pageNo = page),
  setEndpoint: (state, endpoint) => (state.endpoint = endpoint),
  setTotalItems: (state, totalItems) => (state.totalItems = totalItems),
  setPageSize: (state, pageSize) => (state.pageSize = pageSize),
  setFilters: (state, filters) => (state.filters = filters),

  resetState(state, initState = stateInit()) {
    state.items = [];
    state.loading = false;
    state.error = null;
    state.currentPage = 0;
    state.totalItems = 0;
    state.pageSize = 25;
    state.pageNo = 0;
    state.filters = {};
    state.endpoint = '';
  },
};

export default {
  namespaced: true, // Enables namespacing
  state,
  getters,
  actions,
  mutations,
};

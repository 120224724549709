import { MODULE_NAME, PERMISSION } from './permission.constant';
// export const menuItems=[
//   {}
// ]

export const menuItems = [
  {
    permission: PERMISSION.DASHBOARD_READ,
    icon: 'fas fa-tachometer-alt',
    title: 'Dashboard',
    keyId: 'dashboard',
    path: '/dashboard',
    isCollapsing: true,
    children: [],
  },

  {
    permission: PERMISSION.LEADS_READ,
    iconComponent: 'leadsIcon',
    icon: '',
    title: 'Leads',
    path: '/leads',
  },

  {
    permission: PERMISSION.LEADS_READ,
    iconComponent: 'jobcardIcon',
    icon: '',
    title: 'Jobcard',
    path: '/job-cards',
  },

  {
    permission: PERMISSION.LEADS_READ,
    iconComponent: 'quotationIcon',
    icon: '',
    title: 'Quotation',
    path: '/quotations',
  },
  {
    permission: PERMISSION.LEADS_READ,
    iconComponent: 'inventory',
    icon: '',
    title: 'Inventory',
    path: '/inventory/parts',
    children: [
      {
        title: 'Parts',
        path: '/inventory/parts',
        permission: PERMISSION.LEADS_READ,
      },
      {
        title: 'Purchase Request',
        path: '/inventory/purchase-request',
        permission: PERMISSION.LEADS_READ,
      },
      {
        title: 'Stock In',
        path: '/inventory/stock-in',
        permission: PERMISSION.LEADS_READ,
      },
      {
        title: 'Stock Flow',
        path: '/inventory/stock-flow',
        permission: PERMISSION.LEADS_READ,
      },
      {
        title: 'Category',
        path: '/inventory/category',
        permission: PERMISSION.LEADS_READ,
      },
      {
        title: 'Storage',
        path: '/inventory/storage',
        permission: PERMISSION.LEADS_READ,
      },
      {
        title: 'Supplier',
        path: '/inventory/supply',
        permission: PERMISSION.LEADS_READ,
      },
    ],
  },

  {
    permission: PERMISSION.LEADS_READ,
    iconComponent: 'IconPayment',
    icon: '',
    title: 'Payment',
    path: '/payment/invoices',
    children: [
      {
        title: 'Invoice',
        path: '/payment/invoices',
        permission: PERMISSION.LEADS_READ,
      },
      {
        title: 'Payment List',
        path: '/payment/list',
        permission: PERMISSION.LEADS_READ,
      },
    ],
  },

  {
    permission: PERMISSION.USER_READ,
    icon: 'fas fa-user-cog',
    title: 'Users',
    path: '/users/user',
    children: [
      {
        title: 'User Management',
        path: '/users/user',
        permission: PERMISSION.USER_READ,
      },
      {
        title: 'Role & Permission',
        path: '/users/role',
        permission: PERMISSION.ROLE_READ,
      },
    ],
  },

  {
    permission: MODULE_NAME.CONFIGURATION,
    icon: 'fas fa-cog',
    title: 'Configurations',
    path: '/configurations',
    children: [
      {
        title: 'Sources',
        path: '/configurations/sources',
        permission: PERMISSION.SOURCES_READ,
      },

      {
        title: 'Service Type',
        path: '/configurations/service-types',
        permission: PERMISSION.SERVICE_TYPE_READ,
      },

      {
        title: 'Brand',
        path: '/configurations/brands',
        permission: PERMISSION.BRAND_READ,
      },

      {
        title: 'Model',
        path: '/configurations/models',
        permission: PERMISSION.MODEL_READ,
      },

      {
        title: 'Color',
        path: '/configurations/colors',
        permission: PERMISSION.COLOR_READ,
      },

      {
        title: 'Position',
        path: '/configurations/positions',
        permission: PERMISSION.POSITION_READ,
      },

      {
        title: 'Term & Conditions',
        path: '/configurations/term-condition',
        permission: PERMISSION.TERM_CONDITIONS_READ,
      },

      {
        title: 'Address Yescar',
        path: '/configurations/address-yescar',
        permission: PERMISSION.TERM_CONDITIONS_READ,
      },
    ],
  },
];
export const filterMenu = (optionFilter = []) =>
  menuItems.filter(function f(o) {
    if (optionFilter.find((route) => route == o.permission)) return true;

    if (o.children) {
      return (o.children = o.children.filter(f)).length;
    }
  });

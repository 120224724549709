<template>
    <div v-if="hasPermission" class="d-contents">
        <slot></slot>
    </div>
</template>

<script>
    import { PERMISSION } from '@/constants/permission.constant';

    export default {
        name: 'PermissionWrapper',
        props: {
            pms: {
                type: String,
                validator: function (value) {
                    const permissions = Object.values(PERMISSION);
                    return permissions.includes(value);
                },
                required: true
            }

        },
        computed: {
            hasPermission() {
                return this.$checkPermission(this.pms) || this.$userInfo.admin;
            }
        }

    };
</script>

<style scoped>
    .d-contents {
        display: contents;
    }
</style>
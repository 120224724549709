export const TYPE_ACTION = {
  DELETE: 'DELETE',
  EDIT: 'EDIT',
  VIEW: 'VIEW',
  UPDATE: 'UPDATE',
  NEW: 'NEW',
  CREATE: 'CREATE',
  REMARK: 'REMARK',
  UPLOAD_FILE: 'UPLOAD_FILE',
  PRINT: 'PRINT',
};

export const STATUS_DEFAULT = [
  { text: 'Actived', value: 'actived', v2: true },
  { text: 'Inactive', value: 'inactived', v2: false },
  { text: 'Suspended', value: 'suspended', v2: true },
];

export const ENUM_STATUS_DEFAULT = ({ value, fieldName }) => {
  return STATUS_DEFAULT.find((text) => text[`${fieldName || 'v2'}`] == value);
};

export const BTN_TEXT = {
  SAVE: 'Save',
  UPDATE: 'Update',
  Edit: 'Edit',
};

export const STATUS_OPTIONS = {
  NEW: 'New',
  BOOKED: 'Booked',
  CONFIRMED: 'Confirmed',
  FOLLOW_UP: 'Follow Up',
  OPEN_JOBCARD: 'Open Jobcard',
  COMPLETED: 'Completed',
  CANCELED: 'Cancelled',
};

export const ESTATUS_OPTIONS = {
  0: 'New',
  1: 'Confirmed',
  2: 'Follow Up',
  3: 'Booked',
  4: 'Cancelled',
  5: 'Open Jobcard',
  19: 'Completed',
};

export const STATUS_LIST = () => {
  return Object.keys(ESTATUS_OPTIONS).map((status) => ({
    value: status,
    text: ESTATUS_OPTIONS[status],
  }));
};

export const DATE_FORAT = {
  LOCAL_DATE: 'YYYY-MM-DD',
  ISO_8601: 'YYYY-MM-DDTHH:mm:ss',
  ISO_8601_HHMM: 'YYYY-MM-DDTHH:mm',
};

import { PERMISSION } from '@/constants/permission.constant';

const invoiceList = () => import('@/views/invoices/InvoiceList.vue');
const addPayment = () => import('@/views/invoices/forms/CreatePayment.vue');
const editPayment = () => import('@/views/invoices/forms/EditCounterSale.vue');

export const invoicesRoute = [
  {
    path: '/payment/invoices',
    name: 'invoice-list',
    component: invoiceList,
    meta: { requiresAuth: false, permissions: [PERMISSION.LEADS_READ] },
  },

  {
    path: '/payment/invoices/add-payment',
    name: 'add-payment',
    component: addPayment,
    meta: { requiresAuth: false, permissions: [PERMISSION.LEADS_READ] },
  },

  {
    path: '/payment/invoices/edit-payment',
    name: 'edit-payment',
    component: editPayment,
    meta: { requiresAuth: false, permissions: [PERMISSION.LEADS_READ] },
  },
];

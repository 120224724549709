import { TYPE_ACTION } from '@/constants/ConstantType';
import { PERMISSION } from '@/constants/permission.constant';

export const HEADER_TABLE = [
  { key: 'no', label: 'No', title: 'No' },
  {
    key: 'jobCardDate',
    label: 'Jobcard Date',
    title: 'Jobcard Date',
    visible: false,
    disabled: true,
  },
  {
    key: 'jobCardId',
    label: 'Jobcard ID',
    title: 'Jobcard ID',
    visible: false,
    disabled: true,
  },
  {
    key: 'customerName',
    label: 'Customer Name',
    title: 'Customer Name',
    visible: true,
    disabled: false,
  },
  {
    key: 'phoneNumber',
    label: 'Phone Number',
    title: 'Phone Number',
    visible: false,
    disabled: true,
  },
  {
    key: 'brand',
    label: 'Brand',
    title: 'Brand',
    visible: true,
    disabled: false,
  },
  {
    key: 'model',
    label: 'Model',
    title: 'Model',
    visible: true,
    disabled: false,
  },
  {
    key: 'vinNumber',
    label: 'Vin Number',
    title: 'Vin Number',
    visible: false,
    disabled: true,
  },
  {
    key: 'plateNumber',
    label: 'Plate Number',
    title: 'Plate Number',
    visible: false,
    disabled: true,
  },
  {
    key: 'mileage',
    label: 'Mileage',
    title: 'Mileage',
    visible: true,
    disabled: false,
  },
  {
    key: 'serviceType',
    label: 'Service Type',
    title: 'Service Type',
    visible: false,
    disabled: true,
  },
  {
    key: 'serviceAdvisor',
    label: 'Service Advisor',
    title: 'Service Advisor',
    visible: false,
    disabled: true,
  },
  {
    key: 'status',
    label: 'Status',
    title: 'Status',
    visible: false,
    disabled: true,
  },
  {
    key: 'action',
    visible: false,
    disabled: true,
    hide: true,
    thStyle: { textAlign: 'center' },
    tdClass: 'sticky-collumn',
    thClass: 'sticky-collumn',
    action: [
      {
        actionName: TYPE_ACTION.EDIT,
        permission: PERMISSION.JOBCARD_UPDATE,
      },
      {
        actionName: TYPE_ACTION.REMARK,
        permission: PERMISSION.DEFAULT,
      },
      {
        actionName: TYPE_ACTION.UPLOAD_FILE,
        permission: PERMISSION.JOBCARD_UPLOAD_DOC,
      },
      {
        actionName: TYPE_ACTION.PRINT,
        permission: PERMISSION.JOBCARD_PRINT,
      },
    ],
  },
];

export const STATUSES_JOBCARD = [
  {
    value: 5,
    text: 'Open Jobcard',
    color: '#ff9900',
    lookup: [{ value: 15, text: 'Cancelled' }],
  },
  {
    value: 18,
    color: '#14447B',
    text: 'Open Quotation',
    lookup: [{ value: 15, text: 'Cancelled' }],
  },
  {
    value: 6,
    text: 'Work In Progress',
    color: '#A467D2',
    lookup: [
      { value: 6, text: 'Work In Progress' },
      { value: 7, text: 'Partial Part Issue' },
      { value: 20, text: 'Full Part Issue' },
      { value: 8, text: 'Road Test' },
      { value: 9, text: 'QC' },
      { value: 10, text: 'Washing' },
      { value: 11, text: 'Ready for Delivery' },
      { value: 13, text: 'Car Collected' },
      { value: 19, text: 'Completed' },
      {
        value: 15,
        text: 'Cancelled',
      },
    ],
  },
  {
    value: 20,
    text: 'Full Part Issue',
    color: '#D9534F',
    lookup: [
      { value: 7, text: 'Partial Part Issue' },
      { value: 8, text: 'Road Test' },
      { value: 9, text: 'QC' },
      { value: 10, text: 'Washing' },
      { value: 11, text: 'Ready for Delivery' },
      { value: 13, text: 'Car Collected' },
      { value: 19, text: 'Completed' },
    ],
  },
  {
    value: 7,
    text: 'Partial Part Issue',
    color: '#5CB85C',
    lookup: [
      { value: 20, text: 'Full Part Issue' },
      { value: 8, text: 'Road Test' },
      { value: 9, text: 'QC' },
      { value: 10, text: 'Washing' },
      { value: 11, text: 'Ready for Delivery' },
      { value: 13, text: 'Car Collected' },
      { value: 19, text: 'Completed' },
    ],
  },
  {
    value: 8,
    text: 'Road Test',
    color: '#896438',
    lookup: [
      { value: 7, text: 'Partial Part Issue' },
      { value: 20, text: 'Full Part Issue' },
      { value: 9, text: 'QC' },
      { value: 10, text: 'Washing' },
      { value: 11, text: 'Ready for Delivery' },
      { value: 13, text: 'Car Collected' },
      { value: 19, text: 'Completed' },
    ],
  },
  {
    value: 9,
    text: 'QC',
    color: '#FB5B8B',
    lookup: [
      { value: 7, text: 'Partial Part Issue' },
      { value: 20, text: 'Full Part Issue' },
      { value: 8, text: 'Road Test' },
      { value: 10, text: 'Washing' },
      { value: 11, text: 'Ready for Delivery' },
      { value: 13, text: 'Car Collected' },
      { value: 19, text: 'Completed' },
    ],
  },
  {
    value: 10,
    text: 'Washing',
    color: '#5E86AA',
    lookup: [
      { value: 7, text: 'Partial Part Issue' },
      { value: 20, text: 'Full Part Issue' },
      { value: 8, text: 'Road Test' },
      { value: 9, text: 'QC' },
      { value: 11, text: 'Ready for Delivery' },
      { value: 13, text: 'Car Collected' },
      { value: 19, text: 'Completed' },
    ],
  },
  {
    value: 11,
    text: 'Ready for Delivery',
    color: '#E0AF00',
    lookup: [
      { value: 13, text: 'Car Collected' },
      { value: 19, text: 'Completed' },
    ],
  },
  {
    value: 13,
    text: 'Car Collected',
    color: '#D6561F',
    lookup: [
      { value: 19, text: 'Completed' },
      { value: 11, text: 'Ready for Delivery' },
    ],
  },
  {
    value: 19,
    text: 'Completed',
    color: '#2E7EFE',
    lookup: [],
  },
  {
    value: 15,
    text: 'Cancelled',
    color: '#C11D22',
    lookup: [],
  },
];

export const headerTableExport = [
  {
    key: 'no',
    label: 'No',
    title: 'Number',
    visible: false,
    disabled: true,
    thStyle: { minWidth: 'fit-content' },
  },
  {
    key: 'jobcardID',
    label: 'Jobcard ID',
    title: 'Jobcard ID',
    visible: false,
    disabled: true,
  },
  {
    key: 'jobcardDate',
    label: 'Jobcard Date',
    title: 'Jobcard Date',
    visible: false,
    disabled: true,
  },
  {
    key: 'vinNumber',
    label: 'Vin Number',
    title: 'Vin Number',
    visible: false,
    disabled: true,
  },
  {
    key: 'customerName',
    label: 'Customer Name',
    title: 'Customer Name',
    visible: true,
    disabled: false,
  },
  {
    key: 'brand',
    label: 'Brand',
    title: 'Brand',
    visible: true,
    disabled: false,
  },
  {
    key: 'model',
    label: 'Model',
    title: 'Model',
    visible: true,
    disabled: false,
  },
  {
    key: 'year',
    label: 'Year',
    title: 'Year',
    visible: false,
    disabled: false,
  },
  {
    key: 'mileage',
    label: 'Mileage',
    title: 'Mileage',
    visible: false,
    disabled: false,
  },
  {
    key: 'plateNumber',
    label: 'Plate Number',
    title: 'Plate Number',
    visible: true,
    disabled: false,
  },
  {
    key: 'phoneNumber',
    label: 'Phone Number',
    title: 'Phone Number',
    visible: false,
    disabled: true,
  },
  {
    key: 'serviceType',
    label: 'Service Type',
    title: 'Service Type',
    visible: true,
    thStyle: { minWidth: 'fit-content' },
  },
  {
    key: 'serviceAdvisor',
    label: 'Service Advisor',
    title: 'Service Advisor',
    visible: true,
    thStyle: { minWidth: 'fit-content' },
  },
  { key: 'status', label: 'Status', title: 'Status', visible: true },
];

export const CONST_JOBCARD_STATUS = {
  READY_TO_DELIVERY: 11,
  CAR_COLLECTED: 13,
  CANCELED: 15,
  COMPLETED: 19,
};

const PERMISSION = {
  DASHBOARD: 'DASHBOARD',
  DASHBOARD_READ: 'DASHBOARD_READ',

  USER: 'USER',
  USER_READ: 'USER_READ',
  USER_CREATE: 'USER_CREATE',
  USER_UPDATE: 'USER_UPDATE',
  USER_RESET_PASSWORD: 'USER_RESET_PASSWORD',

  ROLE: 'ROLE',
  ROLE_READ: 'ROLE_READ',
  ROLE_CREATE: 'ROLE_CREATE',
  ROLE_UPDATE: 'ROLE_UPDATE',
  ROLE_DELETE: 'ROLE_DELETE',

  LEADS: 'LEADS',
  LEADS_READ: 'LEADS_READ',
  LEADS_CREATE: 'LEADS_CREATE',
  LEADS_UPDATE: 'LEADS_UPDATE',
  LEADS_DELETE: 'LEADS_DELETE',
  LEADS_IMPORT: 'LEADS_IMPORT',
  LEADS_EXPORT: 'LEADS_EXPORT',
  LEADS_PRINT: 'LEADS_PRINT',
  LEADS_UPDATE_SERVICE_ADVISOR:'LEADS_UPDATE_SERVICE_ADVISOR',
  LEADS_DOCUMENT: 'LEADS_DOCUMENT',
  LEADS_UPDATE_JC_STATUS: 'LEADS_UPDATE_JC_STATUS',

  JOBCARD_CREATE:"JOBCARD_CREATE",
  JOBCARD_UPDATE:"JOBCARD_UPDATE",
  JOBCARD_READ:"JOBCARD_READ",
  JOBCARD_DELETE:"JOBCARD_DELETE",
  JOBCARD_UPDATE_STATUS_QC:"JOBCARD_UPDATE_STATUS_QC",
  JOBCARD_EXPORT:"JOBCARD_EXPORT",
  JOBCARD_PRINT:"JOBCARD_PRINT",
  JOBCARD_UPLOAD_DOC:"JOBCARD_UPLOAD_DOC",

  CONFIGURATION: 'CONFIGURATION',

  SOURCES_READ: 'SOURCES_READ',
  SOURCES_CREATE: 'SOURCES_CREATE',
  SOURCES_UPDATE: 'SOURCES_UPDATE',
  SOURCES_DELETE: 'SOURCES_DELETE',

  SERVICE_TYPE_READ: 'SERVICE_TYPE_READ',
  SERVICE_TYPE_CREATE: 'SERVICE_TYPE_CREATE',
  SERVICE_TYPE_UPDATE: 'SERVICE_TYPE_UPDATE',
  SERVICE_TYPE_DELETE: 'SERVICE_TYPE_DELETE',

  POSITION_READ: 'POSITION_READ',
  POSITION_CREATE: 'POSITION_CREATE',
  POSITION_UPDATE: 'POSITION_UPDATE',
  POSITION_DELETE: 'POSITION_DELETE',

  BRAND_READ: 'BRAND_READ',
  BRAND_CREATE: 'BRAND_CREATE',
  BRAND_UPDATE: 'BRAND_UPDATE',
  BRAND_DELETE: 'BRAND_DELETE',

  MODEL_READ: 'MODEL_READ',
  MODEL_CREATE: 'MODEL_CREATE',
  MODEL_UPDATE: 'MODEL_UPDATE',
  MODEL_DELETE: 'MODEL_DELETE',

  COLOR_READ: 'COLOR_READ',
  COLOR_CREATE: 'COLOR_CREATE',
  COLOR_UPDATE: 'COLOR_UPDATE',
  COLOR_DELETE: 'COLOR_DELETE',

  TERM_CONDITIONS_READ: 'TERM_CONDITIONS_READ',
  TERM_CONDITIONS_CREATE: 'TERM_CONDITIONS_CREATE',
  TERM_CONDITIONS_UPDATE: 'TERM_CONDITIONS_UPDATE',
  TERM_CONDITIONS_DELETE: 'TERM_CONDITIONS_DELETE',

  DEFAULT: 'DEFAULT',
  
};

const IS_PERMISSION = {
  DASHBOARD_READ: true,
  USER_READ: true,
  USER_CREATE: true,
  USER_UPDATE: true,
  USER_RESET_PASSWORD: true,
  ROLE_READ: true,
  ROLE_CREATE: true,
  ROLE_UPDATE: true,
  ROLE_DELETE: true,

  LEADS_READ: true,
  LEADS_CREATE: true,
  LEADS_UPDATE: true,
  LEADS_DELETE: true,
  LEADS_IMPORT: true,
  LEADS_EXPORT: true,
  LEADS_PRINT: true,
  LEADS_DOCUMENT: true,
  LEADS_UPDATE_JC_STATUS: true,

  SOURCES_READ: true,
  SOURCES_CREATE: true,
  SOURCES_UPDATE: true,
  SOURCES_DELETE: true,

  SERVICE_TYPE_READ: true,
  SERVICE_TYPE_CREATE: true,
  SERVICE_TYPE_UPDATE: true,
  SERVICE_TYPE_DELETE: true,

  POSITION_READ: true,
  POSITION_CREATE: true,
  POSITION_UPDATE: true,
  POSITION_DELETE: true,

  BRAND_CREATE: false,
  BRAND_DELETE: false,
  BRAND_READ: false,
  BRAND_UPDATE: false,

  MODEL_READ: true,
  MODEL_CREATE: true,
  MODEL_UPDATE: true,
  MODEL_DELETE: true,

  COLOR_READ: true,
  COLOR_CREATE: true,
  COLOR_UPDATE: true,
  COLOR_DELETE: true,

  TERM_CONDITIONS_READ: true,
  TERM_CONDITIONS_CREATE: true,
  TERM_CONDITIONS_UPDATE: true,
  TERM_CONDITIONS_DELETE: true,

  JOBCARD_CREATE:true,
  OBCARD_UPDATE:true,
  JOBCARD_READ:true,
  JOBCARD_DELETE:true,
  JOBCARD_UPDATE_STATUS_QC:true,
  JOBCARD_EXPORT:true,
  JOBCARD_PRINT:true,
  JOBCARD_UPLOAD_DOC:true,

  DEFAULT: true,
};
const MODULE_NAME = {
  DASHBOARD: 'DASHBOARD',
  USER: 'USER',
  ROLE: 'ROLE',
  JOBCARD:'JOBCARD',
  LEADS: 'LEADS',
  CONFIGURATION: 'CONFIGURATION',
};

export const ROLE_USER={
SUPER_ADMIN:'SUPER_ADMIN'
}
export { IS_PERMISSION, MODULE_NAME, PERMISSION };

<template>
    <div style="position: relative;">
        <b-form-select :options="options" multiple v-model="value" :id="inputName" :name="inputName" style="opacity: 0; 
        width: 0; 
        height: 0; position: absolute;
        z-index: -1;" :state="validateState" :value="value" :aria-describedby="feedbackId">
        </b-form-select>
        <b-form-invalid-feedback :id="feedbackId" v-if="!validateState">
            {{ invalidFeedback }}
        </b-form-invalid-feedback>

        <multi-select :disabled="disabled" v-model="inputModelLocal" @select="onSelect" track-by="value"
            :allow-empty="allowEmpty" :multiple="true" :deselect-label="deselectLabel" :options="options"
            :searchable="searchable" :custom-label="customLabel" :close-on-select="closeOnSelect"
            :placeholder="placeholder"></multi-select>
    </div>
</template>

<script>
    import { v4 as uuidv4 } from 'uuid';
    export default {
        name: 'AppMultiSelectForm',
        props: {
            disabled: {
                type: Boolean,
                default: false,
            },
            label: {
                type: String,
                required: false,
                default: '',
            },
            inputId: {
                type: String,
                required: false,
                default: () => uuidv4(),

            },
            inputName: {
                type: String,
                required: false,
            },

            customClass: {
                type: String,
                default: '',
            },
            invalidFeedback: {
                type: String,
                default: '',
            },
            feedbackId: {
                type: String,
                default: '',
            },
            validateState: {
                type: Boolean,
                default: null,
            },
            inputModel: {
                type: [String, Number],
                default: '',
            },
            value: {
                type: [Array],
                default: () => [],
            },
            selectModel: {
                type: [Array, Object, String, Number],
                default: () => [],
            },
            isDisabled: {
                type: Boolean,
                default: false,
            },
            trackBy: {
                type: String,
                default: 'value',
            },
            allowEmpty: {
                type: Boolean,
                default: true,
            },
            deselectLabel: {
                type: String,
                default: 'Select Existed',
            },
            options: {
                type: Array,
                // required: true,
                default: () => [],
            },
            searchable: {
                type: Boolean,
                default: true,
            },
            customLabel: {
                type: Function,
                default: null,
            },
            closeOnSelect: {
                type: Boolean,
                default: false,
            },
            placeholder: {
                type: String,
                default: 'Select an option..',
            },
        },
        watch: {
            options: {
                handler(val) {
                    this.inputModelLocal = val?.filter((v) =>
                        this.value?.find((m) => m == v?.value));// eslint-disable-line
                }, deep: true
            },


        },

        data() {
            return {
                // Add any data here
                inputModelLocal: '',
                selectModelLocal: {},
            };
        },
        methods: {
            onSelect(selectedOption) {
                this.$emit('input', this.inputModelLocal.map(m => m.value));
                this.$emit('update', selectedOption.value);
                this.$emit('update:selectModel', selectedOption);
            },
        },
        mounted() {
            this.inputModelLocal = this.options?.filter((v) =>
                this.value?.find((m) => m == v?.value));


            this.selectModelLocal = this.selectModel;
        }
    };
</script>

<style scoped>
    /* Add any custom styles here */
</style>
import { PERMISSION } from '@/constants/permission.constant';

const quotationList = () => import('@/views/quotation/QuotationList.vue');
const quotCunterSlae = () => import('@/views/quotation/forms/CounterSale.vue');
const editQuotation = () => import('@/views/quotation/forms/EditQuotation.vue');
const editCunterSlae = () => import('@/views/quotation/forms/EditCounterSale.vue');

export const quotationRoute = [
  {
    path: '/quotations',
    name: 'quotation-list',
    component: quotationList,
    meta: { requiresAuth: false, permissions: [PERMISSION.LEADS_READ] },
  },

  {
    path: '/quotations/counter-sale',
    name: 'add-counter-sale',
    component: quotCunterSlae,
    meta: { requiresAuth: false, permissions: [PERMISSION.LEADS_READ] },
  },

  {
    path: '/quotations/edit-quotation',
    name: 'edit-quotation',
    component: editQuotation,
    meta: { requiresAuth: false, permissions: [PERMISSION.LEADS_READ] },
  },

  {
    path: '/quotations/edit-counter-sale',
    name: 'edit-counter-sale',
    component: editCunterSlae,
    meta: { requiresAuth: false, permissions: [PERMISSION.LEADS_READ] },
  },
];

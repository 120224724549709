import { fetchData } from './data-api.service';

const stateInit = (tableName = '') => ({
  items: [],
  loading: false,
  error: null,
  currentPage: 1,
  totalItems: 0,
  pageSize: 25,
  pageNo: 1,
  filters: {},
  tableName,
});

let state = stateInit();

const getters = {
  allItems: (state) => state.items,
  isLoading: (state) => state.loading,
  fetchError: (state) => state.error,
  currentPage: (state) => state.currentPage,
  totalItems: (state) => state.totalItems,
  pageSize: (state) => state.pageSize,
  filters: (state) => state.filters,
};

const actions = {
  resetState({ commit, state }) {
    commit('resetState', state.tableName);
  },
  async getItems({ commit, state }, { endpoint, pageNo, pageSize, filters }) {
    commit('setLoading', true);
    try {
      const params = { pageNo, pageSize, ...filters };

      const data = await fetchData(endpoint, { ...params, pageNo: (params?.pageNo || 1) - 1 });

      const { content: items, totalElements: totalItems } = data;

      commit('setItems', items);
      commit('setTotalItems', totalItems);
      commit('setPageSize', pageSize);
    } catch (error) {
      commit('setError', error);
    } finally {
      commit('setLoading', false);
    }
  },
  setFilters({ commit, dispatch }, { endpoint, filters }) {
    commit('setFilters', filters);
    dispatch('getItems', { endpoint, page: 1, pageSize: state.pageSize, filters });
  },
};

const mutations = {
  setItems: (state, items) => (state.items = items),
  setLoading: (state, loading) => (state.loading = loading),
  setError: (state, error) => (state.error = error),
  setCurrentPage: (state, page) => (state.currentPage = page),
  setTotalItems: (state, totalItems) => (state.totalItems = totalItems),
  setPageSize: (state, pageSize) => (state.pageSize = pageSize),
  setFilters: (state, filters) => (state.filters = filters),
  setTableName: (state, tableName) => (state.tableName = tableName),
  resetState(state, tableName) {
    Object.assign(state, stateInit(tableName));
  },
};

export default {
  namespaced: true, // Enables namespacing
  state,
  getters,
  actions,
  mutations,
};
